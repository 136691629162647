<template>
  <div class="cloud-acceleration">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/cloudAcceleration/top.png"
    />
    <van-form>
      <van-field
        v-model.trim="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
        @input="phoneChange"
      />
      <div class="flex">
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="数字验证码"
          class="input-code"
        />
        <div>
          <div class="code" v-show="sendAuthCode" @click="getAuthCode">
            获取验证码
          </div>
        </div>
        <div class="code" v-show="!sendAuthCode">
          <span>{{ auth_time }}秒</span>
        </div>
      </div>

      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/cloudAcceleration/submit.png"
        class="submit"
        @click="onSubmit"
      />
    </van-form>
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/cloudAcceleration/card1.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/cloudAcceleration/card3.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/cloudAcceleration/card4.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/cloudAcceleration/info1.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/cloudAcceleration/info2.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/cloudAcceleration/info3.png"
    />
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAnHui/manguo/anh_mango_symbol.png"
    />
  </div>
</template>

<script>
import { tradeOrder, getVerificationCode } from "@/api/qiuMoFusion/index";
import tailJumpMixin from "@/utils/tailJump.js";
import { JuDuoDuo } from "@/utils/common";

export default {
  mixins: [tailJumpMixin],
  data() {
    return {
      form: {
        productNo: "304201318733",
        phone: "",
        characteristic: "",
        marketUrl: "",
        sid: "",
        data: "",
        code: "",
        orderId: "",
      },
      customer: {
        visibility: false,
      },
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
    };
  },
  mounted() {
    const that = this;
    window.onpopstate = function (event) {
      window.location.replace(
        that.tailJumpUrl(that.link, "QIUMORONGHE", that.form.phone, "退出")
      );
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/LTAnHui/cloudAccelerationDouYin26`;
    },
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      characteristic: from + reasonCode,
      marketUrl: href,
      phone: phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  methods: {
    async phoneChange(value) {},
    onSubmit() {
      let filterPhone = /^1[3456789]\d{9}$/;
      let filtercode = /^\d{6}$/;

      const { phone = "", code = "" } = this.form;
      if (!phone) {
        this.$toast.fail("请输入手机号");
      } else if (!filterPhone.test(phone)) {
        this.$toast.fail("手机号错误");
      } else if (!code) {
        this.$toast.fail("请输入验证码");
      } else if (!filtercode.test(code)) {
        this.$toast.fail("验证码错误");
      } else {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      let filtercode = /^\d{6}$/;
      if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        this.visibility = false;
        if (res.code == 0) {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          JuDuoDuo("100023", this.form.phone, "安徽联通20元云加速权益包");
        } else {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(this.link, "QIUMORONGHE", this.form.phone, "失败")
            );
          }, 3000);
        }
      });
    },
    getAuthCode() {
      if (this.yzm_loading) return;
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.yzm_loading = true;
      getVerificationCode(
        JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
      )
        .then((res) => {
          if (res.code != 0) {
            this.$dialog.alert({
              message: res.data.message || "验证码获取失败",
            });
            setTimeout(() => {
              window.location.replace(
                this.tailJumpUrl(
                  this.link,
                  "QIUMORONGHE",
                  this.form.phone,
                  "验证码下发失败"
                )
              );
            }, 3000);
          } else {
            // 设置倒计时秒
            this.sendAuthCode = false;
            this.auth_time = 120;
            let auth_timetimer = setInterval(() => {
              this.auth_time--;
              if (this.auth_time <= 0) {
                this.sendAuthCode = true;
                clearInterval(auth_timetimer);
              }
            }, 1000);
          }
        })
        .finally(() => {
          // 结束加载状态
          this.yzm_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cloud-acceleration {
  background-color: #c4ebfc;

  .img {
    width: 100%;
  }

  ::v-deep .van-form {
    background: transparent;
    padding-top: 40px;

    .flex {
      margin-top: 20px;
      display: flex;
      align-items: center;
      padding: 0 40px;
      box-sizing: border-box;

      .code {
        flex-shrink: 0;
        width: 229px;
        height: 90px;
        text-align: center;
        line-height: 90px;
        color: #fff;
        font-size: 28px;
        font-weight: normal;
        border-radius: 20px;
        background-color: #2c7fed;
      }
    }

    .van-cell {
      width: 670px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 20px;
      margin: 0 auto;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 70px;

      &.input-code {
        flex: 1 1 auto;
        margin-right: 30px;
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 32px;
        font-weight: 400;

        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 400;
          color: #a8a8a8;
        }
      }
    }

    .submit {
      width: 600px;
      display: block;
      margin: 50px auto 30px;
      animation: identifier 1.5s infinite;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }

        25% {
          transform: scale(1.15);
        }

        50% {
          transform: scale(1);
        }

        75% {
          transform: scale(1.15);
        }
      }
    }
  }
}
</style>
